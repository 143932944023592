// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".GJAgoQ0UgSfnxbunUtGF {\n    width: 193px;\n    display: flex;\n    flex-direction: column;\n\n    font-family: 'Formular';\n    font-style: normal;\n    font-weight: 500;\n    font-size: 24px;\n    line-height: 30px;\n\n    text-align: center;\n    text-transform: uppercase;\n}\n\n.bTvmW6yNciB_qPtZEDJQ {\n    color: var(--primary-color);\n}\n\n.AJ5albGG4ptgxQPzObl6 {\n    color: var(--accent-color);\n}", "",{"version":3,"sources":["webpack://./src/components/Game/components/Counter/counter.module.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,aAAa;IACb,sBAAsB;;IAEtB,uBAAuB;IACvB,kBAAkB;IAClB,gBAAgB;IAChB,eAAe;IACf,iBAAiB;;IAEjB,kBAAkB;IAClB,yBAAyB;AAC7B;;AAEA;IACI,2BAA2B;AAC/B;;AAEA;IACI,0BAA0B;AAC9B","sourcesContent":[".counter {\n    width: 193px;\n    display: flex;\n    flex-direction: column;\n\n    font-family: 'Formular';\n    font-style: normal;\n    font-weight: 500;\n    font-size: 24px;\n    line-height: 30px;\n\n    text-align: center;\n    text-transform: uppercase;\n}\n\n.title {\n    color: var(--primary-color);\n}\n\n.value {\n    color: var(--accent-color);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"counter": "GJAgoQ0UgSfnxbunUtGF",
	"title": "bTvmW6yNciB_qPtZEDJQ",
	"value": "AJ5albGG4ptgxQPzObl6"
};
export default ___CSS_LOADER_EXPORT___;
