// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".LQadWSixUZaE7Nej_0gB {\n    height: 115px;\n    width: 115px;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n}\n\n.TUxULxjFAY007nDyUcOQ {\n    background-color: var(--primary-color);\n    color: var(--secondary-color);\n}\n\n.lwKNV67qT_fXijPQNoVC {\n    background-color: #fff;\n}\n\n.dYkb3fXLQrFqnNNRgdor {\n    background-color: var(--secondary-color);\n}\n\n.EqHeBl83c69mIuICGC1N {\n    width: 61px;\n    height: 27px;\n    margin: auto;\n}", "",{"version":3,"sources":["webpack://./src/components/Game/components/CardItem/card-item.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,YAAY;IACZ,aAAa;IACb,mBAAmB;IACnB,uBAAuB;AAC3B;;AAEA;IACI,sCAAsC;IACtC,6BAA6B;AACjC;;AAEA;IACI,sBAAsB;AAC1B;;AAEA;IACI,wCAAwC;AAC5C;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,YAAY;AAChB","sourcesContent":[".card {\n    height: 115px;\n    width: 115px;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n}\n\n.close {\n    background-color: var(--primary-color);\n    color: var(--secondary-color);\n}\n\n.hidden {\n    background-color: #fff;\n}\n\n.open {\n    background-color: var(--secondary-color);\n}\n\n.upperImage {\n    width: 61px;\n    height: 27px;\n    margin: auto;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card": "LQadWSixUZaE7Nej_0gB",
	"close": "TUxULxjFAY007nDyUcOQ",
	"hidden": "lwKNV67qT_fXijPQNoVC",
	"open": "dYkb3fXLQrFqnNNRgdor",
	"upperImage": "EqHeBl83c69mIuICGC1N"
};
export default ___CSS_LOADER_EXPORT___;
